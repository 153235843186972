import { useEffect } from 'react';
import { useTranslation, useToast, Notification, Theme, useNextgenRouter } from '@nextgen-web-framework/all';
import { type FeatureFlag, UserType } from '@nextgen-web-framework/core';
import { componentRegistry, componentTransformerRegistry } from '@commerce-webcomponents/ui';
import { CmsComponent, ModularBlockComponent } from '@commerce-webcomponents/ui-composite';
import Layout from '../../components/Layout/Layout';
import withNextGenISR, { isValidUserType } from '../../utils/withNextGenISR';
import { CMSTransformerDataType, PageCategory } from '../../types/common';
import SEOComponent from '../../components/SEOComponent/SEOComponent';
import { getInvalidateTime, getBannerComponentType } from '../../utils/common';
import styles from './home.module.scss';
import { getContentEssentials } from 'utils/getEssentials';
import useLivePreview from 'live-preview/useLivePreview';
import COMPONENT_KEY from 'components/ProgressiveProfileWrapper/types/propsTypes';
import ProgressiveProfileWrapper from 'components/ProgressiveProfileWrapper/ProgressiveProfileWrapper';
import transformProfileWrapper from 'components/ProgressiveProfileWrapper/transformProfileWrapper';
import { staticLocalePaths } from 'utils/staticLocalePaths';
import { sanitizationConfig } from 'utils/sanitizationUtils';
import { TemplateType, getComponentStylesConfig } from 'utils/componentsStylingConfigs';

export const getStaticPaths = staticLocalePaths;

interface HomePageProps {
  homeData: CMSTransformerDataType;
  seoData: CMSTransformerDataType;
  profileStepperData: CMSTransformerDataType;
  showError: boolean;
  userType: string;
  featureFlags: FeatureFlag;
}

componentRegistry[COMPONENT_KEY.PROFILE_STEPPER] = ProgressiveProfileWrapper;
componentTransformerRegistry[COMPONENT_KEY.PROFILE_STEPPER] = transformProfileWrapper;

const Home = ({ seoData, homeData, profileStepperData, userType }: HomePageProps) => {
  const t = useTranslation();
  const router = useNextgenRouter();
  const socialAccount = router.query?.get('social-linking');
  const toastMessage = `${t('amshop.home.linkSocialAccount.Description').replace(
    '{{socialAccount}}',
    `${socialAccount}`,
  )}`;
  const [homePageData, seoState] = useLivePreview(homeData, seoData);
  const header = homePageData?.layout?.header;
  const footer = homePageData?.layout?.footer;
  const { addToast, deleteToast } = useToast();
  useEffect(() => {
    if (router.query && router.query.has('social-linking')) {
      const id = 'social-linking-id';
      addToast({
        id,
        timeout: 3000,
        content: <Notification theme={Theme.SUCCESS} onCloseClick={() => deleteToast(id)} message={toastMessage} />,
      });
    }
  }, [router?.query]);

  const componentStylesConfig = getComponentStylesConfig(TemplateType.MAIN_LANDING);

  return (
    <Layout hasTopMobileHeader={true} header={header} footer={footer}>
      <SEOComponent {...seoState} userType={userType} />
      {homePageData?.hero_section && (
        <div className={styles['page-banner']}>
          <CmsComponent
            componentKey={getBannerComponentType(homePageData?.hero_section?.banner_type)}
            data={homePageData?.hero_section}
          />
        </div>
      )}
      {profileStepperData && <CmsComponent componentKey="profile_stepper" data={profileStepperData} />}
      {homePageData?.body_section && (
        <ModularBlockComponent
          data={homePageData?.body_section}
          sanitizeComponentsConfig={sanitizationConfig}
          componentStylesConfig={componentStylesConfig}
        />
      )}
    </Layout>
  );
};

export const getStaticProps = withNextGenISR(async (context) => {
  if (context.params?.userType && !isValidUserType(context.params?.userType as string)) {
    return { notFound: true };
  }
  const pageContent = await getContentEssentials({
    locale: context.params?.locale as string,
    uId: 'main_landing_template',
    params: [
      {
        key: 'query',
        value: JSON.stringify({ url: '/home', account_type: { account_type: context?.params?.userType } }),
      },
    ],
  });
  const { data: homeData } = pageContent;
  const { showError } = pageContent;
  const seoData = homeData?.seo_config ?? null;
  let profileStepper = {
    data: null,
  };
  if (process.env.NEXT_PUBLIC_PROFILE_TASKS_ENABLED && context?.params?.userType !== UserType.ANONYMOUS) {
    profileStepper = await getContentEssentials({
      locale: context.params?.locale as string,
      uId: 'profile_stepper',
    });
  }
  const { data: profileStepperData } = profileStepper;
  return {
    props: {
      homeData,
      seoData,
      showError,
      profileStepperData,
      userType: context?.params?.userType,
    },
    revalidate: getInvalidateTime(PageCategory.EXPRESS),
  };
});

export default Home;
